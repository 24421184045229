<template>
    <div id="app" class="vitn">
      <div v-if="generalMsg.text != ''"  class="top-banner"         :style="  `background-color:` + generalMsg.background_color + `; color:` + generalMsg.text_color + `;` "       >
        <span class="texttop" v-html="generalMsg.text"></span>
      </div>
      <router-view />
    </div>
  </template>
  <script>
  import { isServer } from "@storefront/core/helpers";
  import config from "@config";
  const contentList = [
        "footer_copyright"
  ];
  const sliders = [
    "home",
    "homepage_categories",
    "top_header_icons"
  ];
  
  export default {
    name: "Home",
    metaInfo() {
      return {
        htmlAttrs: { lang: this.$i18n.locale },
        meta: [
          { charset: "utf-8" },
          { name: "viewport", content: "width=device-width, initial-scale=1" },
        ],
        titleTemplate: "%s | " + config.app_name,
      };
    },
    computed: {
      cmsDefaultPageContent() {
        return this.$store.state.cmsPage.default;
      },
      generalMsg() {
        return this.$store.state.messages.generalMsg;
      },
      currentTemplate() {
        return config.template.name;
      },
    },
    watch: {
      "$store.state.messages.msgItems": async function () {
        const msgs = await this.$store.dispatch("messages/giveLastMessages");
        msgs.forEach(function (msg) {
          this.makeToast(msg);
        }, this);
      },
    },
    components: {},
    async serverPrefetch() {
      this.$store.dispatch("forms/loadForms");
      await this.$store.dispatch("messages/loadGeneralMsg");
      await this.$store.dispatch("menu_theme/loadMenu");
      await this.$store.dispatch("user/loadCountries");
      await this.$store.dispatch("product/productReviewRatingsMetadata");
      await this.$store.dispatch("stores/load");
      await this.$store.dispatch("cmsPage/loadDefault", {
        id: "home",
      });
      await this.$store.dispatch("sliders/multiple", {
        key: "identifier",
        value: sliders,
      });
  
      await this.$store.dispatch("cmsBlock/multiple", {
        key: "identifier",
        value: contentList,
      });
      await this.$store.dispatch("cart/loadPickupLocations");
    },
    created() {
      if (!isServer) {
        if (this.$cookies.get("websiteCookie") == "true") {
          this.$gtm.enable(true);
          this.$gtm.debug(true);
        }
      }
    },
    async mounted() {
      if (this.currentTemplate == "esf_kerkrade_vitanatura") {
        const app = document.getElementById("app");
        if (!app.classList.contains("vitn")) {
          app.classList.add("vitn");
        }
      }
  
      await this.$store.dispatch("user/loadUser");
      if (this.$store.getters["cart/getCartIsLoaded"] == false) {
        await this.$store.dispatch("cart/loadCart");
      }
      if (!isServer && config.helloRetail && config.helloRetail.enabled) {
        let helloRetail = document.createElement("script");
        helloRetail.async = true;
  
        if (config.helloRetail.code) {
          this.loadHelloRetail();
        }
      }
    },
    methods: {
      makeToast(msg) {
        this.$bvToast.toast(msg.text, {
          variant: msg.type,
          autoHideDelay: 5000,
          toaster: "b-toaster-top-full",
          solid: true,
          append: true,
        });
      },
      loadHelloRetail() {
        let helloRetail = document.createElement("script");
        helloRetail.async = true;
        if (config.helloRetail && config.helloRetail.code) {
          helloRetail.src =
            "https://d1pna5l3xsntoj.cloudfront.net/scripts/company/awAddGift.js#" +
            config.helloRetail.code;
          document.head.appendChild(helloRetail);
        }
      },
    },
    updated() {
      if (this.currentTemplate == "esf_kerkrade_vitanatura") {
        const app = document.getElementById("app");
  
        if (!app.classList.contains("vitn")) {
          app.classList.add("vitn");
        }
      }
      this.$nextTick(function () {
        if (self.ADDWISH_PARTNER_NS) {
          self.ADDWISH_PARTNER_NS.api.reload();
        } else {
          this.loadHelloRetail();
        }
      });
    },
  };
  </script>
  
  <style lang="scss">
  .toast-body {
    text-align: center;
    font-size: 12px;
    font-weight: 500;
  }
  #app {
    position: relative;
    width: 100%;
    overflow: hidden;
    min-height: 100vh;
  
    .modal-open & {
      width: 100vw;
    }
  }
  
  .texttop {
    display: inline-block;
    position: relative;
    margin-top: 5px;
    margin-bottom: -15px;
  }
  
  .top-banner {
    text-align: center;
    font-size: 16px;
    letter-spacing: 0.22rem;
    position: relative;
  }
  
  a {
    transition: 0.3s color ease-in-out;
  }
  
  .resize-active * {
    transition: none !important;
  }
  .best-seller--category {
    font-size: 24px;
    letter-spacing: -0.025em;
    font-weight: normal;
    display: inline-block;
    vertical-align: top;
    background: $black;
    color: $white;
    padding: 6px 10px;
    min-width: 213px;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
  }
  
  .section-title {
    font-size: 18px;
    font-weight: normal;
    display: block;
    text-align: center;
    margin-bottom: 19px;
  }
  </style>
  